@import "../../../colors";

.FormBuilderContent {
  position: relative;
  flex: 1;
  background-color: $grayBackground;

  .form-builder-content-inner {
    background-color: #f2f2f2;
    padding: 10px;
    position: absolute;
    height: 100%;
    width: 100%;
    overflow-y: auto;

    .content-card {
      background-color: #fff;
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
      min-height: 100%;
      display: flex;
      flex-direction: row;

      @media (min-width: 850px) {
        &.left {
          flex-direction: row;

          .layout-container {
            flex: 1;
          }
        }
  
        &.right {
          flex-direction: row-reverse;

          .layout-container {
            flex: 1;
          }
        }
      }

      .form-items-container-outer {
        flex: 1;
        min-height: 100%;
        padding: 50px 10px;

        .form-items-container {
          border-radius: 4px;
          padding: 20px;
          margin-left: auto;
          margin-right: auto;
          min-height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;

          .section-item-container {
            border: 1px solid rgba(0, 0, 0, 0);
            position: relative;

            .section-item-actions-container {
              position: absolute;
              right: 5px;
              top: -14px;
              flex-direction: row;
              display: none;

              .section-item-action-button {
                height: 25px;
                width: 25px;
                border-radius: 50%;
                background-color: rgb(248, 249, 250);
                padding: 0;
                margin: 0 2px;
      
                &:hover {
                  opacity: 1;
                  background-color: #F1F3F4;
                }
      
                i {
                  font-size: 12px;
                  color: $primaryColor;
                }
              }
            }

            &.selected {
              border-color: $primaryColorLight;
            }

            &:hover {
              border-color: $primaryColorLight;

              .section-item-actions-container {
                display: flex;
              }
            }
          }
        }
      }
    }
  }

  &.thumbnail {
    height: 101px;
    margin: -1px;
    overflow: hidden;

    .form-builder-content-inner {
      padding: 0;
      border-radius: 4px;
      zoom: 0.2;
      -moz-transform: scale(0.2);
      overflow: hidden;

      .content-card {
        height: 100%;
      }
    }
  }
}
