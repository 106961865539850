@import "../../../../colors";

.FolderTile {
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  -webkit-touch-callout: none;

  &:hover {
    opacity: 0.8;
  }

  .folder-image {
    height: 150px;
    position: relative;
    text-align: center;

    i {
      font-size: 150px;
      color: #04223e;
    }
  }

  .folder-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 10px 10px;
    text-align: center;
    color: #04223e;
  }
}

.FolderTileContextMenu {

  .item-container {
    padding: 7px;
    width: 225px;
    cursor: pointer;
    border-bottom: 1px solid $borderLight;
    color: #04223e;

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }

    &.disabled {
      opacity: 0.5;
      background-color: rgba(0, 0, 0, 0);
      cursor: initial;
    }

    i {
      margin-right: 12px;
      width: 20px;
    }

    img {
      margin-right: 12px;
      width: 20px;
      margin-bottom: -4px;
    }
  }
}
