@import "../../../../colors";

.LayoutMenu {
  position: relative;
  padding: 10px 5px;
  display: flex;
  background-color: rgba(0, 0, 0, 0.01);

  .option-container {
    padding: 2px;
    background-color: #fff;
    border: 1px solid $primaryColor;
    margin: 0 5px;

    img {
      height: 20px;
    }

    .left-container {
      display: flex;
      flex-direction: row;
      height: 60px;

      .left {
        width: 50px;
        background-color: $primaryColor;
        border-radius: 4px 0 0 4px;
      }

      .right {
        width: 50px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        text-align: center;
      }
    }

    .center-container {
      background-color: $primaryColor;
      border-radius: 4px;
      width: 100px;
      height: 60px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      img {
        filter: brightness(0) invert(1);
      }
    }

    .right-container {
      display: flex;
      flex-direction: row;
      height: 60px;

      .left {
        width: 50px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        text-align: center;
      }

      .right {
        width: 50px;
        background-color: $primaryColor;
        border-radius: 0 4px 4px 0;
      }
    }
  }

  @media (max-width: 360px) {
    flex-direction: column;
    padding: 5px 10px;

    .option-container {
      padding: 2px;
      background-color: #fff;
      border: 1px solid $primaryColor;
      margin: 5px 0;
    }
  }
}
