@import "../../../colors";

.AssetPickerModal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999999;

  .modal-body {
    position: absolute;
    background-color: #fff;
    padding: 20px;
    padding-top: 30px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 3px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    min-width: 950px;
    max-height: calc(100vh - 5px);
    overflow: auto;

    .close-button {
      height: 26px;
      width: 26px;
      border-radius: 26px;
      text-align: center;
      cursor: pointer;
      right: 5px;
      top: 5px;
      position: absolute;

      i {
        margin-top: 5px;
        color: gray;
      }
    }

    .close-button:hover {
      background-color: #eee;
    }

    .title {
      margin: 0;
      margin-bottom: 10px;
      border-bottom: 1px solid #ddd;
      padding-bottom: 10px;
    }

    .modal-content {
      margin-bottom: 10px;

      .items-container {
        margin-top: 10px;

        .item-container {
          width: 20%;
          display: inline-block;
          padding: 3px;

          .item-outer {
            border: 1px solid $borderLight;
            border-radius: 4px;
            padding-top: 100%;
            position: relative;
            overflow: hidden;

            &.add-button {
              border: none;
            }

            .item {
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              cursor: pointer;

              .add-item-button {
                height: 100%;
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: space-around;

                &:hover {
                  opacity: 0.9;
                }

                p {
                  font-weight: 600;
                  margin: 0;
                  margin-bottom: 25px;
                }

                i {
                  font-size: 55px;
                }
              }

              .item-image-container {
                width: 100%;
                height: 100%;
                position: relative;
                background-color: #fafafa;

                &:hover {
                  opacity: 0.9;
                }

                img {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translateX(-50%) translateY(-50%);
                  max-width: 100%;
                  max-height: 100%;
                  background: #fff;
                }
              }
            }
          }
        }

        @media (max-width: 970px) {
          .item-container {
            width: 25%;
          }
        }

        @media (max-width: 750px) {
          .item-container {
            width: 33.33%;
          }
        }

        @media (max-width: 600px) {
          .item-container {
            width: 50%;
          }
        }

        @media (max-width: 450px) {
          .item-container {
            width: 100%;
          }
        }
      }
    }

    .buttons-container {
      display: flex;
      justify-content: flex-end;

      button {
        margin-left: 5px;
      }
    }
  }

  @media (max-width: 970px) {
    .modal-body {
      padding: 10px;
      padding-top: 20px;
      min-width: calc(100% - 30px);
    }
  }
}
