@import "../../../colors";

.FormBuilderSidebar {
  .sidebar-spacer {
    width: 200px;

    &.closed {
      width: 0;
    }

    @media (max-width: 900px) {
      width: 0;
    }
  }

  .sidebar-inner {
    width: 200px;
    border-right: 1px solid $borderDark;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    z-index: 1;
    position: absolute;
    height: 100%;

    &.closed {
      margin-left: -200px;
    }

    .sidebar-main-container {
      flex: 1;
      overflow: scroll;

      .page-tile-container {
        padding: 10px;

        .page-tile {
          background-color: #fff;
          border: 1px solid $borderLight;
          border-radius: 4px;
          height: 100px;
          cursor: pointer;
          overflow: hidden;
          position: relative;

          &:hover {
            opacity: 0.8;
          }

          .page-tile-cover {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0);
            z-index: 1;
          }
        }

        &.active {
          background-color: $primaryColorLight;

          .page-tile:hover {
            opacity: 1;
          }
        }
      }
    }

    .sidebar-footer {
      border-top: 1px solid $borderDark;
      padding: 7px;
      display: flex;
      flex-direction: row;
      position: relative;

      .sidebar-footer-left {
        flex: 1;
      }

      .sidebar-close-button-container {

        button {
          padding: 2px 9px;
          border-radius: 50%;
          background-color: rgb(248, 249, 250);

          &:hover {
            opacity: 1;
            background-color: #F1F3F4;
          }

          i {
            font-size: 12px;
            color: $primaryColor;
          }
        }
      }

      .sidebar-open-button-container {
        position: absolute;
        right: -30px;
        width: 30px;
        top: -1px;

        button {
          margin-left: 0px;
          padding: 6px 13px;
          border-radius: 0 16px 16px 0;

          &:hover {
            opacity: 1;
            padding-left: 20px;
          }

          i {
            font-size: 12px;
          }
        }

        &.hide {
          display: none;
        }
      }
    }
  }
}
