@import "../../../colors";

.Forms {
  padding: 20px;

  .breadcrumbs-container {
    margin-bottom: 10px;
    display: flex;
    margin-bottom: 10px;
  
    a {
      color: $linkColor;
    }

    .breadcrumb-item-container {
      display: flex;

      .breadcrumb-item {
        font-size: 14px;
        margin-right: 5px;
      }
    }
  }

  .top-row {
    display: flex;
    flex-direction: row;
    align-items: center;

    .header-container {
      flex: 1;
    }

    .buttons-container {
      display: flex;
      flex-direction: row;

      button.add-folder {
        margin-left: 10px;
      }

      @media (max-width: 500px) {
        flex-direction: column;

        button.add-folder {
          margin-left: 0;
          margin-top: 10px;
        }
      }
    }
  }

  .items-container {

    .item-container {
      width: 20%;
      display: inline-block;
      padding: 20px;
      text-decoration: none;
    }

    @media (max-width: 1300px) {
      .item-container {
        width: 25%;
      }
    }

    @media (max-width: 1100px) {
      .item-container {
        width: 33.33%;
      }
    }

    @media (max-width: 850px) {
      .item-container {
        width: 50%;
      }
    }

    @media (max-width: 450px) {
      .item-container {
        width: 100%;
      }
    }
  }
}

.edit-folder-modal {
  margin-top: -20px;
  margin-bottom: -10px;

  small {
    font-size: 11px;
    color: #777777;
  }
}
