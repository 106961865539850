@import "../../../colors";

.FormBuilderBackgroundModal {
  position: relative;

  .background-modal-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
    
    .separate-background-toggle-container {
      display: flex;
      flex-direction: row;
      align-items: center;

      .separate-background-toggle-inner {
        margin-right: 15px;
        background-color: $grayBackground;
        height: 12px;
        border-radius: 6px;
        width: 32px;
        position: relative;
        cursor: pointer;

        .separate-background-toggle-handle {
          position: absolute;
          height: 18px;
          width: 18px;
          border-radius: 18px;
          top: -2.5px;
          background-color: $primaryColor;
          transition: transform 0.2s;
        }

        &.active {
          background-color: $alternateColor;

          .separate-background-toggle-handle {
            transform: translateX(100%);
            background-color: $secondaryColor;
          }
        }
      }
    }
      
    button {
      background-color: #fff;
      border: 1px solid $borderDark;
      color: $primaryColor;
      display: flex;
      flex-direction: row;
      align-items: center;

      .color-preview {
        height: 20px;
        width: 20px;
        margin-right: 8px;
        border: 1px solid $borderLight;
        border-radius: 50%;
      }
    }
  }
}

.color-picker-container {
  overflow: hidden;
}
