@import "colors";

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  line-height: 1.6;
  font-size: 16px;
  overflow-x: hidden;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01em;
  color: #04223e;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fefefe;
}

table {
	width: 100%;
}

*, *::before, *::after {
    box-sizing: border-box;
}

figure,
ol,
ul {
	margin: 0
}

h1,
h2,
h3,
h4,
h5,
h6 {
	line-height: 1.2;
}

a {
	color: #000;
  text-decoration: none;
}

.container {
  max-width: 61rem;
  padding: 0 1rem;
  margin: 0 auto;
}

button,
.button {
  background-color: $primaryColor;
  color: #fff;
  border-radius: 4px;
  padding: 0;
  border: none;
	padding: 10px 14px;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

  &.secondary {
    background-color: $secondaryColor;
  }

  &.success {
    background-color: $successColor;
  }

  &.alternate {
    background-color: $alternateColor;
  }

  &.gradient {
    background-color: $alternateColor;
    background-image: linear-gradient(to right, $secondaryColor, $alternateColor);
  }

  &.small {
    font-size: 14px;
    padding: 6px 12px;
  }
}

button:hover,
.button:hover {
  opacity: 0.8;
}

button.alternate:hover,
.button.alternate:hover {
  background-color: $alternateColor;
}

button.success:hover,
.button.success:hover {
  background-color: $successColor;
}

button:focus,
.button:focus {
  outline: 0;
}

button:disabled {
  background-color: #aaa !important;
  background-image: none;
  cursor: not-allowed;
}

label {
  color: #777777;
  margin-bottom: 0;
  font-size: 14px;
}

select {
	background: linear-gradient(45deg, transparent 50%, #000 50%), linear-gradient(135deg, #000 50%, transparent 50%), linear-gradient(to right, #fff, #fff);
	background-position: calc(100% - 21px) calc(1em + 2px), calc(100% - 16px) calc(1em + 2px), 100% 0;
	background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
	background-repeat: no-repeat;
	line-height: 1.4;
	background-color: #ffffff;
	border-radius: 4px;
	position: relative;
	color: #404040;
	box-shadow: none;
	border: 1px solid #bfbfbf;
	margin-bottom: 15px;
	padding: 5.5px 12px 5.5px;
	width: 100%;
	font-size: 16px;
	margin: 0;
	box-sizing: border-box;
	-webkit-appearance: none;
	-moz-appearance: none;
	line-height: 1.75;
}

select:focus {
  outline: none;
}

input,
textarea {
	border: 1px solid #212121;
  border-radius: 4px;
  border-color: #bfbfbf;
	display: inline-block;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
	font-size: 16px;
	line-height: 1;
	max-width: 100%;
	padding: 10px 14px;
  width: 100%;
  color: #404040;

  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #aaa;
    opacity: 1; /* Firefox */
    font-weight: normal;
  }

  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #aaa;
    font-weight: normal;
  }

  &::-ms-input-placeholder { /* Microsoft Edge */
    color: #aaa;
    font-weight: normal;
  }
}

textarea {
  resize: vertical;
}

.input-container {
  margin-top: 10px;
}

input[type="checkbox"] {
  width: auto;
  cursor: pointer;
}

.well {
  background: #FFFFFF;
  border: 1px solid $borderDark;
  border-radius: 6px;
  padding: 20px 25px;

  &.well-secondary {
    border-color: rgba(255, 51, 18, 0.2);
  }

  &.well-light {
    border-color: $borderLight;
  }

  &.well-large {
    border-radius: 20px;
  }
}

.divider {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);

  &.divider-light {
    border-bottom: 1px solid $borderDark;
  }
}
