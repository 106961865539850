.textFieldItemStyle {

  .label-input {
    padding: 0;
    border: none;
    background-color: rgba(0, 0, 0, 0);
    margin-bottom: 7px;
  }

  .sublabel-input {
    padding: 0;
    border: none;
    background-color: rgba(0, 0, 0, 0);
    font-size: 12px;
    margin-top: -5px;
  }

  input:focus {
    outline-width: 0;
  }

  .drag-input-container {
    position: relative;
    display: flex;

    .input-cover {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }

    textarea {
      resize: none;
    }
  }
}
