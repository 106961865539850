.ContextMenu {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999999;

  .menu-body {
    position: absolute;
    z-index: 999999;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    overflow: auto;
    width: fit-content;
    max-height: calc(100vh - 50px);
  }
}
