@import "../../../colors";

.FormBuilderHeader {
  border-bottom: 1px solid $borderDark;

  &.closed {
    display: none;
  }

  .menu-item {
    position: relative;

    .menu-button {
      background-color: #fff;
      color: #04223e;
      cursor: pointer;
      font-size: 14px;
      letter-spacing: .2px;
      padding: 4px 6px;
      font-weight: 500;

      &:hover {
        opacity: 1;
        background-color: #F1F3F4;
      }
    }
  }

  .header-top {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid $borderDark;
    padding: 5px 15px;
    justify-content: space-between;
    align-items: center;

    .header-left {
      display: flex;
      flex-direction: row;
      align-items: center;

      .back-button-container {
        margin-right: 10px;

        button {
          border-radius: 50%;
        }
      }

      @media (max-width: 600px) {
        flex-direction: column;
        align-items: flex-start;

        .back-button-container {
          margin-bottom: 7px;
        }
      }

      .menu-container {

        .menu-top-row {
          display: flex;
          flex-direction: row;
          align-items: center;

          .name-container {
            width: fit-content;
            position: relative;
            margin-right: 7px;

            .name-filler {
              padding: 2px 10px 2px 5px;
              font-size: 18px;
              font-weight: 600;
              border: 1px solid #fff;
            }

            input {
              padding: 2px 5px;
              border-color: rgba(0, 0, 0, 0);
              font-size: 18px;
              font-weight: 600;
              width: 100%;
              position: absolute;
              top: 0;

              &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                font-size: 18px;
                font-weight: 600;
              }

              &::-moz-placeholder { /* Firefox 19+ */
                font-size: 18px;
                font-weight: 600;
              }

              &:-ms-input-placeholder { /* IE 10+ */
                font-size: 18px;
                font-weight: 600;
              }

              &:-moz-placeholder { /* Firefox 18- */
                font-size: 18px;
                font-weight: 600;
              }

              &:hover {
                border-color: #bfbfbf;
              }
            }
          }

          .icon-buttons-container {
            display: flex;
            flex-direction: row;

            button {
              background-color: #fff;
              padding: 2px;
              margin-top: 3px;

              &:hover {
                background-color: #F1F3F4;
              }

              img {
                opacity: 0.6;
                height: 17px;
                display: block;
              }
            }
          }
        }

        .menu-bottom-row {
          display: flex;
          flex-direction: row;
        }
      }
    }

    .header-right {
      @media (max-width: 600px) {
        position: absolute;
        top: 10px;
        right: 15px;
      }

      button {

        &.share-button {
          margin-right: 7px;
        }

        .fa-share {
          margin-left: 6px;
        }

        .fa-save {
          margin-right: 6px;
        }
      }
    }
  }

  .header-bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 5px 15px;

    .header-bottom-left {
      display: flex;
      flex-direction: row;
      align-items: center;

      .menu-pipe {
        background-color: $borderLight;
        width: 1px;
        height: 20px;
      }

      .menu-item {
        margin-right: 3px;
        margin-left: 3px;

        &:first-of-type {
          margin-left: 0;
        }

        .new-page-button {
          background-color: rgb(248, 249, 250);
          background-color: $primaryColor;
          color: #fff;

          &.medium {
            display: none;
          }

          &.small {
            display: none;
            border-radius: 50%;
            width: 28px;
          }

          @media (max-width: 400px) {
            &.large {
              display: none;
            }

            &.medium {
              display: inherit;
            }
          }

          @media (max-width: 375px) {
            &.medium {
              display: none;
            }

            &.small {
              display: inherit;
            }
          }

          &:hover {
            opacity: 0.8;
            background-color: $primaryColor;
          }

          i {
            font-size: 12px;
            color: #fff;
            margin-right: 7px;
          }
        }
      }
    }

    .header-bottom-right {
      .header-close-button-container {

        button {
          padding: 3px 8px;
          border-radius: 50%;
          background-color: rgb(248, 249, 250);

          &:hover {
            opacity: 1;
            background-color: #F1F3F4;
          }

          i {
            font-size: 12px;
            color: $primaryColor;
          }
        }
      }
    }
  }
}

.header-open-button-container {
  position: fixed;
  top: -2px;
  right: 10px;
  height: 30px;
  z-index: 1;

  button {
    margin-left: 0px;
    padding: 6px 11px;
    border-radius: 0 0 16px 16px;

    &:hover {
      opacity: 1;
      padding-top: 13px;
    }

    i {
      font-size: 12px;
    }
  }

  &.hide {
    display: none;
  }
}
