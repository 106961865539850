@import "../../colors";

.FormBuilder {
  display: flex;
  flex-direction: column;

  .form-builder-inner {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
  
    .main-container {
      position: relative;
      height: 100%;
  
      .main-container-inner {
        display: flex;
        flex-direction: row;
        height: 100%;
        border-bottom: 1px solid $borderLight;
      }
    }
  }
}
