@import "../../../colors";

.Team {
  padding: 20px;

  .tab-container {
    border: 1px solid $borderDark;
    background-color: #fff;

    .header {
      padding: 15px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-bottom: 1px solid $borderLight;

      h1 {
        margin: 0;
      }
    }
  }
}
